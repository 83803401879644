<template>
  <div class="landing-image-large" aria-label="Mice ">
    <v-row>
      <v-col cols="12" xs="6" sm="0" md="6" xl="6"></v-col>
      <v-col cols="12" xs="8" sm="12" md="6" xl="5">
        <div class="implant-img">
          <img src="@/assets/images/ImplantFleche.webp" alt="Implant Arrow" />
        </div>
        <div class="title-container-1">
          <h1>MiceTracking</h1>
          <h2>{{ $t("page-home.underTitle") }}</h2>
          <div>
            <router-link to="/contact" class="button-contact">{{
              $t("button.contact")
            }}</router-link>
          </div>
        </div>
        <div class="implant-container-large">
          <img src="@/assets/images/Implant.webp" alt="" />
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import navbar from "@/components/NavBar.vue";

export default {
  components: {
    navbar,
  },
  metaInfo: {
    title: "",
    meta: [
      {
        name: "",
        content: "",
      },
      {
        name: "keywords",
        content: "",
      },
    ],
  },
  data() {
    return {
      scrollPosition: 0,
      maxScroll: 125, // Par exemple, la position maximale où l'image doit s'arrêter
      showCalendar: false,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      this.scrollPosition = window.scrollY;
      const implantContainer = this.$el.querySelector(".implant-container-large");
      let translateY = Math.min(this.scrollPosition / 2, this.maxScroll);
      implantContainer.style.transform = `translateY(${translateY}px)`;
    },
  },
};
</script>

<style>
.landing-image-large {
  background-image: url("../../assets/images/LandingPage1.webp");
  background-color: #c8d2dc;
  background-size: cover;
  background-repeat: no-repeat;
  display: block;
  margin-top: -100px;
  /* Vérifiez si c'est vraiment nécessaire et ajustez si besoin */
  overflow: hidden;
}

.title-container-1 {
  margin-top: 150px;
}

.title-container-1 h1 {
  color: var(--vertfonce);
}

.title-container-1 h2 {
  color: var(--bleu);
}

.title-container-1 div {
  margin-top: 50px;
}

.title-container div {
  width: 100%;
  display: flex;
  justify-content: right;
}

.implant-container-small,
.implant-img,
.souris-container,
.encart-small {
  display: none;
}

.implant-container-large {
  display: block;
  position: relative;
  top: 100px;
  margin-left: 12vw;
  transition: transform 0.3s ease-out;
}

.implant-container-large img {
  width: 15vw;
}

.calendar-container {
  background-color: white;
  border-radius: 15px;
}

.text-landing {
  font-family: "Roboto", Regular;
  font-size: 1.4rem;
  color: var(--gris);
  text-align: justify;
}

.encart-blanc {
  display: block;
  background-color: white;
  border-radius: 20px;
  opacity: 0;
  margin-top: 37vw;
  width: 90%;
  padding: 40px;
  transition: transform 1s ease-out, opacity 0.7s ease-out;
}

.copyright1 {
  text-align: center;
  font-size: 21px;
  color: #333;
  /* Ensure this contrasts with the background */
  margin-top: 50px;
  width: 100%;
}

@media (max-width: 960px) {
  .implant-container-large {
    display: none;
  }

  .landing-image-large {
    background-image: none;
    margin-top: -250px;
    height: fit-content;
    padding-bottom: 20px;
  }

  .implant-container-small {
    margin-top: 320px;
    width: 100%;
    display: flex;
    justify-content: center;
    transition: transform 0.3s ease-out;
  }

  .button-contact {
    font-size: 16px;
    padding: 10px 20px;
  }

  .implant-container-small img {
    width: 40%;
  }

  .implant-img {
    display: flex;
    justify-content: center;
    margin-top: 300px;
  }

  .implant-img img {
    width: 60%;
  }

  .title-container-1 {
    margin-left: 10%;
    width: 80%;
    text-align: center;
  }

  .title-container div {
    margin-top: 5px;
    justify-content: center;
  }

  .encart-blanc {
    display: none;
  }

  .encart-small {
    display: block;
    background-color: white;
    border-radius: 20px;
    opacity: 0;
    margin-top: -350px;
    width: 80%;
    padding: 40px;
    transition: transform 1s ease-out, opacity 0.7s ease-out;
  }

  .text-landing {
    font-size: 1.2rem;
  }

  .souris-container {
    width: 100%;
    display: flex;
    justify-content: left;
  }

  .souris-container img {
    width: 90%;
  }

  .copyright1 {
    display: none;
  }
}

@media (max-width: 767px) {
  .implant-container-small {
    width: 100%;
  }

  .encart-small {
    background-color: white;
    margin-top: -150px;
  }
}

@media (max-width: 1700px) {
  .title-container-1 {
    margin-top: 100px;
  }

  .title-container-1 div {
    margin-top: 20px;
  }
}
</style>
