<template>
  <div style="height: 100%">
    <v-row justify="center">
      <v-col cols="12" sm="10" md="12" lg="10" xl="8" class="mb-50">
        <h2>{{ $t("page-home.presentation.title") }}</h2>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="12" sm="10" md="6" lg="5" xl="4">
        <div class="part-2">
          <div class="paragraph">
            <p>
              {{ $t("page-home.presentation.paragraph1") }} <br /><br />
              {{ $t("page-home.presentation.paragraph2") }} <br /><br />
            </p>
          </div>
          <div class="image-container-2 hide-on-mobile">
            <img src="@/assets/images/souris-filmée.webp" alt="souris filmée" />
          </div>
        </div>
      </v-col>
      <v-col cols="12" sm="10" md="6" lg="5" xl="4" class="col2">
        <div class="image-container">
          <img src="@/assets/illustrations/SystèmePresentation.webp" alt="" />
        </div>
        <div class="paragraph mt-25">
          <p>{{ $t("page-home.presentation.paragraph3") }}<br /><br /></p>

          <h5 style="font-size: 20px">
            {{ $t("page-home.presentation.titleparagraph4") }}
          </h5>
          <br />
          <p>
            {{ $t("page-home.presentation.paragraph4") }}
          </p>
          <div class="button-position">
            <router-link to="/contact?subjectIndex=0" class="link-download">{{ $t("button.brochure") }}
            </router-link>
          </div>
        </div>
        <div class="image-container-2 show-on-mobile">
          <img src="@/assets/images/souris-filmée.webp" alt="souris filmée" />
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  computed: {
    downloadLink() {
      return this.$i18n.locale === "fr"
        ? "/pdf/MiceTrackingBrochureFR.pdf"
        : "/pdf/MiceTrackingBrochureUS.pdf";
    },
  },
};
</script>

<style scoped>
.col2 {
  height: 100%;
}

.image-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border-radius: 40px;
  background-color: var(--grisclair);
  height: 100%;
  padding: 20px;
}

.image-container img {
  width: 100%;
}

.image-container-2 {
  display: flex;
  flex-direction: column;
  border-radius: 40px;
  height: 100%;
  margin-top: 20px;
  overflow: hidden;
}

.image-container img {
  width: 100%;
}

.paragraph {
  background-color: var(--white);
  border-radius: 40px;
  padding: 30px;
  text-align: justify;
}

.button-position {
  display: flex;
  justify-content: right;
  margin-top: 50px;
}

.part-2 h2 {
  padding-left: 30px;
  width: 75%;
}

.hide-on-mobile {
  display: flex;
}

.show-on-mobile {
  display: none;
}

@media (max-width: 960px) {
  .image-container {
    margin-top: 50px;
  }

  .part-2 h2 {
    padding-left: 30px;
    width: 80%;
  }

  .hide-on-mobile {
    display: none;
  }

  .show-on-mobile {
    display: flex;
  }
}
</style>
