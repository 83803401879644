<template>
  <div v-if="showBanner" class="cookie-banner">
    <div class="cookie-title">{{ $t("bannerCookie.title") }}</div>
    <p class="cookie-text">
      {{ $t("bannerCookie.text") }}
      <router-link to="/privacy">{{ $t("bannerCookie.privacyLink") }}</router-link
      >.
    </p>
    <div class="cookie-actions">
      <button @click="acceptAll" class="btn-accept">
        {{ $t("bannerCookie.actions.accept") }}
      </button>
      <button @click="continueWithoutAccepting" class="btn-continue">
        {{ $t("bannerCookie.actions.continue") }}
        <span>{{ $t("bannerCookie.actions.continueArrow") }}</span>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "CookieBanner",
  data() {
    return {
      showBanner: false,
    };
  },
  mounted() {
    const consent = localStorage.getItem("cookieConsent");
    if (consent === "accepted") {
      this.loadGA();
    } else if (!consent) {
      this.showBanner = true;
    }
  },
  methods: {
    acceptAll() {
      localStorage.setItem("cookieConsent", "accepted");
      this.showBanner = false;
      this.loadGA();
    },
    continueWithoutAccepting() {
      localStorage.setItem("cookieConsent", "refused");
      this.showBanner = false;
    },
    personalize() {
      alert("Page de personnalisation à implémenter");
    },
    loadGA() {
      if (!window.gtagInitialized) {
        // Stub pour éviter l'erreur avant que gtag.js soit chargé
        window.dataLayer = window.dataLayer || [];
        window.gtag = function () {
          window.dataLayer.push(arguments);
        };

        const script = document.createElement("script");
        script.async = true;
        script.src = "https://www.googletagmanager.com/gtag/js?id=G-PFF3LEJMVN";
        document.head.appendChild(script);

        script.onload = () => {
          window.gtag("js", new Date());
          window.gtag("config", "G-PFF3LEJMVN", { anonymize_ip: true });
          window.gtagInitialized = true;
        };
      }
    },
  },
};
</script>

<style scoped>
.cookie-banner {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 320px;
  background: #ffffff;
  border: 1px solid #ddd;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  padding: 16px;
  z-index: 1000;
  font-family: Arial, sans-serif;
}

.cookie-title {
  font-weight: bold;
  font-size: 14px;
  color: #333;
  margin-bottom: 8px;
}

.cookie-text {
  font-size: 12px;
  color: var(--bleu);
  margin-bottom: 12px;
  line-height: 1.4;
}

.cookie-text a {
  color: var(--bleu);
  text-decoration: underline;
}

.cookie-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
}

.btn-personalize {
  font-size: 12px;
  color: #000;
  background: none;
  border: none;
  text-decoration: underline;
  cursor: pointer;
  padding: 0;
}

.btn-accept {
  font-size: 12px;
  color: #fff;
  background: var(--vertfonce);
  border: none;
  border-radius: 9999px;
  padding: 8px 16px;
  cursor: pointer;
  font-weight: bold;
}

.btn-continue {
  font-size: 12px;
  color: var(--vertfonce);
  background: none;
  border: none;
  cursor: pointer;
  text-align: left;
  padding: 0;
  font-weight: 500;
}
</style>
