<template>
  <div>
    <nav-bar />
    <accueil-image />
    <accueil-presentation class="padding-resp mt100" />
    <accueil-fonctionnement-bis class="padding-resp-2 mt100" />
    <accueil-benefices class="padding-resp mt100" />
    <avantages class="padding-resp mt100" />
    <CookiesBanner />
    <foot class="mt100" />
  </div>
</template>

<script>
import NavBar from "../../components/NavBar.vue";
import AccueilImage from "../../components/PageAccueil/AccueilImage.vue";
import AccueilPresentation from "../../components/PageAccueil/AccueilPresentation.vue";
import AccueilBenefices from "../../components/PageAccueil/AccueilBenefices.vue";
import AccueilFonctionnement from "../../components/PageAccueil/AccueilFonctionnement.vue";
import foot from "@/components/FooterBis.vue";
import avantages from "../../components/PageAccueil/AccueilAvantages.vue";
import AccueilFonctionnementBis from "../../components/PageAccueil/AccueilFonctionnementBis.vue";
import CookiesBanner from "../../components/cookiesBanner.vue";

export default {
  components: {
    NavBar,
    AccueilPresentation,
    AccueilBenefices,
    AccueilFonctionnement,
    AccueilImage,
    avantages,
    CookiesBanner,
    foot,
    AccueilFonctionnementBis,
  },
  metaInfo() {
    return {
      title: this.$t("page-home.metaTitle"),
      link: [
        {
          rel: "canonical",
          href: `https://micetracking.com${this.$route.path}`,
        },
      ],
      meta: [
        {
          name: "description",
          content: this.$t("page-home.metaDescription"),
        },
        {
          name: "keywords",
          content: this.$t("page-home.metaKeywords"),
        },
      ],
    };
  },
};
</script>
